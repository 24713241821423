import { ChatClient } from '@ably/chat';
import * as Ably from 'ably';

export class ChatService {
  private static instance: ChatService | null = null;

  public ablyClient!: Ably.Realtime; // Use the '!' operator to bypass TypeScript's checks

  private chatClient!: ChatClient;

  // Singleton pattern with dynamic re-initialization
  public static getInstance(): ChatService {
    if (!ChatService.instance) {
      ChatService.instance = new ChatService();
    }
    return ChatService.instance;
  }

  // Initialize or re-initialize the ChatService with a new clientId
  public initialize(clientId: string): void {
    this.ablyClient = new Ably.Realtime({
      key: '1oXNKg.ITjldQ:mJiXXp5zYq0ImGdNqaGcPgK6qmSqMi5qbTg_sn1k7BY',
      clientId,
    });
    this.chatClient = new ChatClient(this.ablyClient);
  }

  public getChatClient(): ChatClient {
    return this.chatClient;
  }
}

export const chatService = ChatService.getInstance();
