'use client';

import { QueryClientProvider } from '@tanstack/react-query';
import dynamic from 'next/dynamic';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useAuthContext } from 'src/auth/hooks';
import queryClient from 'src/services/queryClient';

import { POSTHOG } from '../config-global';

// Dynamically load AblyRealtimeProvider
const AblyRealtimeProvider = dynamic(() => import('./AblyProvider'), {
  ssr: false, // Client-side only
});

const isProdEnvironment = typeof window !== 'undefined' && window.location.host === 'trybe.fm';

export function PHProvider({ children }: PropsWithChildren) {
  const [posthogInitialized, setPosthogInitialized] = useState(false);
  const { user } = useAuthContext(); // Access the user from AuthContext

  useEffect(() => {
    if (isProdEnvironment && POSTHOG.publicKey && !posthogInitialized) {
      posthog.init(POSTHOG.publicKey, {
        api_host: POSTHOG.host,
        capture_pageview: false, // Disable automatic pageview capture
      });
      setPosthogInitialized(true); // Mark Posthog as initialized
    }
  }, [posthogInitialized, user]);

  console.log('PHProvider', isProdEnvironment, posthogInitialized, user);

  // Conditional Rendering Logic
  const renderWithPosthogAndAbly = (
    <AblyRealtimeProvider>
      <PostHogProvider client={posthog}>
        <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
      </PostHogProvider>
    </AblyRealtimeProvider>
  );

  const renderWithAblyOnly = (
    <AblyRealtimeProvider>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </AblyRealtimeProvider>
  );

  const renderWithoutAbly = (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );

  // Return based on the environment, posthog, and user conditions
  if (user) {
    if (isProdEnvironment && posthogInitialized) {
      return renderWithPosthogAndAbly;
    }
    return renderWithAblyOnly;
  }
  return renderWithoutAbly;
}
