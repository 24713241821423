import axios from 'axios';

import { UpsertDeviceTokenDTO } from '../types';
import { axiosInstance, endpoints } from '../utils/axios';

export async function changePassword(oldPassword: string, newPassword: string): Promise<void> {
  await axiosInstance.post(endpoints.user.changePassword, {
    newPassword,
    oldPassword,
  });
}

export async function sendResetPasswordMail(email: string): Promise<void> {
  await axiosInstance.post(endpoints.auth.verifyMail, {
    email,
  });
}

export async function verifyResetToken(token: string): Promise<void> {
  const response = await axiosInstance.post(endpoints.auth.verifyResetToken, {
    token,
  });

  if (response.status !== 201) {
    throw new Error('Wrong or expired token');
  }
}

export async function exists(email: string): Promise<boolean> {
  const response = await axios.get<boolean>(endpoints.user.exists, { params: { email } });
  return response.data;
}

export async function resetPassword(email: string, newPassword: string): Promise<void> {
  await axiosInstance.post(endpoints.auth.resetPassword, {
    email,
    newPassword,
    token: localStorage.getItem('resetToken') || '',
  });
}

export async function upsertDevicePushToken(payload: UpsertDeviceTokenDTO) {
  const response = await axiosInstance.post(endpoints.devicePushTokens.upsert, payload);
  return response.data;
}
